import { mergeDeepRight, prop } from 'ramda'

export const PLATFORM =
  typeof navigator !== 'undefined' && navigator.product === 'ReactNative'
    ? 'app'
    : 'web'

// exported for tests
export const requiredModules = ['Sentry']

let config = {}

let modules = {}

export const getConfig = () => config

export const getModule = (modulesName) => modules[modulesName]

export const configureFrontend = (c) => {
  config = mergeDeepRight(config, c.config)
  modules = mergeDeepRight(modules, c.modules)

  requiredModules.forEach((moduleName) => {
    const isModuleConfigured = !!prop(moduleName, modules)

    if (!isModuleConfigured) {
      // eslint-disable-next-line no-console
      console.error(`Module ${moduleName} is not configured in packages/core`)
    }
  })
}

// exported for tests
export const resetConfiguration = () => {
  config = {}
  modules = {}
}
