export const globalStylesheet = {
  html: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    height: '100%',
    overflow: 'hidden',
  },
  body: {
    height: '100%',
    overflow: 'auto',
  },
  '#root': {
    height: '100%',
    display: 'flex',
    backgroundColor: '#ffffff',
  },
  '#root > [data-radium]': {
    display: 'flex',
    flex: 1,
  },
}
