import de from '@socialquiz/strings/src/de.json'
// import en from '@socialquiz/strings/src/en.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const init = (appLanguage, plugins = []) => {
  i18n.use(initReactI18next)

  for (const plugin of plugins) {
    i18n.use(plugin)
  }

  i18n.init({
    // lng: appLanguage,
    // fallbackLng: 'en',
    lng: 'de',
    debug: __DEV__,
    resources: {
      de: { translation: de },
      // en: { translation: en },
    },
  })
}

export const I18n = i18n
