import * as t from './types'

export const init = (payload = {}) => ({
  type: t.INIT,
  payload,
})

export const load = (payload = {}) => ({
  type: t.LOAD,
  payload,
})

export const mount = (payload = {}) => ({
  type: t.MOUNT,
  payload,
})

export const navigation = (payload = {}) => ({
  type: t.NAVIGATION,
  payload,
})

export const navigationReady = (payload = {}) => ({
  type: t.NAVIGATION_READY,
  payload,
})

export const bottomTabDoublePress = (payload = {}) => ({
  type: t.BOTTOM_TAB_DOUBLE_PRESS,
  payload,
})

export const bottomTabPress = (payload = {}) => ({
  type: t.BOTTOM_TAB_PRESS,
  payload,
})

export const ready = (payload = {}) => ({
  type: t.READY,
  payload,
})

export const auth = (payload = {}) => ({
  type: t.AUTH,
  payload,
})

export const unauth = (payload = {}) => ({
  type: t.UNAUTH,
  payload,
})

export const start = (payload = {}) => ({
  type: t.START,
  payload,
})

export const background = (payload = {}) => ({
  type: t.BACKGROUND,
  payload,
})

export const foreground = (payload = {}) => ({
  type: t.FOREGROUND,
  payload,
})

export const resume = (payload = {}) => ({
  type: t.RESUME,
  payload,
})

export const session = (payload = {}) => ({
  type: t.SESSION,
  payload: {
    isInitial: true,
    duration: 0,
    ...payload,
  },
})

export const screenshot = (payload = {}) => ({
  type: t.SCREENSHOT,
  payload,
})
