import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { configureApi } from '@socialquiz/core/api'
import { configureFrontend } from '@socialquiz/core/configure'
import { configureHelpers } from '@socialquiz/core/helpers'
import { init as initI18n } from '@socialquiz/core/i18n'
import LanguageDetector from 'i18next-browser-languagedetector'
import { config } from './config'

initI18n(undefined, [LanguageDetector])

Sentry.init({
  ...config.Sentry.config,
  integrations: (payload) =>
    payload
      .map((integration) => {
        switch (integration.name) {
          case 'Breadcrumbs': {
            integration._options = {
              console: false,
              dom: false,
              fetch: false,
              history: false,
              sentry: false,
              xhr: false,
            }
            break
          }
          default:
            break
        }

        return integration
      })
      .concat([new BrowserTracing()]),
})

configureApi(config.api)
configureHelpers(config.helpers)
configureFrontend({ config, modules: { Sentry } })
