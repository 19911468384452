import { OPEN_IN_APP } from '@socialquiz/core/types/resultsTypes'
import { takeLatest } from 'redux-saga/effects'
import { stringify } from 'qs'

const onOpenInApp = function* (action) {
  const link = `https://quokka.qa/${action.payload?.username ?? ''}`

  /**
   * https://firebase.google.com/docs/dynamic-links/create-manually
   */
  const url = `https://play.quokka.qa/?${stringify({
    link,
    apn: 'de.socialquiz.app',
    ibi: 'de.socialquiz.app',
    isi: 1265133033,
    ius: 'quokka',
    efr: 1,
  })}`

  window.open(url, '_self')
}

export const actionHandler = {
  [OPEN_IN_APP]: [takeLatest, onOpenInApp],
}
