import { bool } from 'prop-types'
import React from 'react'
import { Radium } from '../hocs/Radium'

class TextComponent extends React.Component {
  static childContextTypes = {
    isInAParentText: bool,
  }

  static contextTypes = {
    isInAParentText: bool,
  }

  getChildContext() {
    return { isInAParentText: true }
  }

  render() {
    const Component = this.context.isInAParentText ? 'span' : 'div'

    return (
      <Component
        ref={this.props.forwardedRef}
        onClick={this.props.onPress}
        style={{
          color: this.props.color,
          bottom: this.props.bottom,
          flex: this.props.flex,
          fontFamily: this.props.fontFamily ?? 'Poppins',
          fontSize: this.props.fontSize ?? 16,
          fontStyle: this.props.fontStyle,
          fontVariant: this.props.fontVariant,
          fontWeight: this.props.fontWeight,
          left: this.props.left,
          letterSpacing: this.props.letterSpacing,
          lineHeight: this.props.lineHeight,
          marginBottom: this.props.marginBottom,
          marginHorizontal: this.props.marginHorizontal,
          marginLeft: this.props.marginLeft,
          marginRight: this.props.marginRight,
          marginTop: this.props.marginTop,
          marginVertical: this.props.marginVertical,
          padding: this.props.padding,
          paddingBottom: this.props.paddingBottom,
          paddingHorizontal: this.props.paddingHorizontal,
          paddingLeft: this.props.paddingLeft,
          paddingRight: this.props.paddingRight,
          paddingTop: this.props.paddingTop,
          paddingVertical: this.props.paddingVertical,
          position: this.props.position,
          right: this.props.right,
          textAlign: this.props.textAlign,
          textDecorationLine: this.props.textDecorationLine,
          textShadowColor: this.props.textShadowColor,
          textShadowOffset: this.props.textShadowOffset,
          textShadowRadius: this.props.textShadowRadius,
          textTransform: this.props.textTransform,
          top: this.props.top,
          writingDirection: this.props.writingDirection,
          zIndex: this.props.zIndex,
          ...this.props.style,
        }}>
        {this.props.children}
      </Component>
    )
  }
}

const RadiumText = Radium(TextComponent)

export const Text = React.forwardRef((props, ref) => {
  return <RadiumText {...props} forwardedRef={ref} />
})
