import { LOG_LEVEL } from '@socialquiz/core/store/middleware/createLogger'
import storage from 'redux-persist/lib/storage'

/**
 * type reduxLogger = {
 *   actionType: string,
 *   logLevel: string,
 *   module: string,
 * }
 */
const reduxLogger = {
  actionType: 'analytics/ADD_EVENT',
  logLevel: LOG_LEVEL.ALL,
  module: 'analytics',
  eventValidation: false,
}

const appVersion = APP_VERSION

export const config = {
  api: {
    client: `web:${appVersion}`,
    errorBlacklist: [],
    homepage:
      process.env.REACT_APP_ENV === 'development'
        ? 'http://localhost:3000'
        : process.env.REACT_APP_ENV === 'testing'
        ? 'https://quokka.qa'
        : process.env.REACT_APP_ENV === 'staging'
        ? 'https://staging.quokka.qa'
        : 'https://quokka.qa',
    host:
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'testing'
        ? 'https://testapi.quokka.qa'
        : 'https://api.quokka.qa',
    isWeb: true,
    limit: window.quokka.isDesktop() ? 24 : 12,
    maxAnswerImageSize: 5000000,
    maxAvatarSize: 500000,
    userimg: {
      thumb: 'https://userimg.quokka.qa/thumb',
      xs: 'https://userimg.quokka.qa/xs',
      md: 'https://userimg.quokka.qa/md',
      lg: 'https://userimg.quokka.qa/lg',
      xs_v2: 'https://userimg.quokka.qa/xs-v2',
      md_v2: 'https://userimg.quokka.qa/md-v2',
      lg_v2: 'https://userimg.quokka.qa/lg-v2',
    },
    updateInterval: process.env.REACT_APP_ENV === 'development' ? 60000 : 30000,
  },
  snapchatSignIn: {
    clientId:
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'testing'
        ? '76e40257-ce18-402c-ba93-e84314f4673c'
        : 'b0bc891b-622a-475d-b1d1-6b3daaef1d0c',
    redirectURI:
      process.env.REACT_APP_ENV === 'development'
        ? 'http://localhost:3000'
        : process.env.REACT_APP_ENV === 'testing'
        ? 'https://quokka.qa'
        : process.env.REACT_APP_ENV === 'staging'
        ? 'https://staging.quokka.qa'
        : 'https://quokka.qa',
    scope: ['user.display_name', 'user.bitmoji.avatar', 'user.external_id'],
  },
  reduxLogger,
  Sentry: {
    config: {
      autoSessionTracking: false,
      debug: __DEV__,
      dsn: 'https://e8178c5b7f5946c28cf252faafc4bbef@o74781.ingest.sentry.io/6437474',
      enabled: !__DEV__,
      environment: process.env.REACT_APP_ENV,
      release: appVersion,
      sampleRate: 0.5,
      allowUrls: [
        /https?:\/\/(cdn|www|www2|www2-staging|hanneszeigmaldrauf)\.quokka\.qa\/static\/js/,
        /https?:\/\/unpkg.com/,
      ],
      denyUrls: [
        /extensions\//i,
        /^chrome:\/\//i,
        /^.*pageFold\/ftpagefold_v.*\.js$/,
        /^https:\/\/raw\.githubusercontent\.com\/ampproject\/amphtml\/.*\/ads\/.*$/,
        /^\/diff\/templates\/ts\/dist\/viewability\/.*\.js$/,
      ],
      ignoreErrors: [
        '[undefined]: [undefined]',
        /\[undefined\]/,
        /Failed to fetch/,
        /Image is not a constructor/,
        /^Failed to load.*Document is already detached\.$/,
      ],
    },
  },
  store: {
    offline: {
      persistOptions: {
        key: 'reduxPersist',
        storage,
      },
    },
  },
}
