export const AUTHENTICATE = 'auth/AUTHENTICATE'
export const AUTHENTICATE_WITH_SNAPCHAT_SUCCESS = 'auth/AUTHENTICATE_WITH_SNAPCHAT_SUCCESS' // prettier-ignore

export const LOGIN_WITH_SNAPCHAT = 'auth/LOGIN_WITH_SNAPCHAT'
export const LOGIN_WITH_SNAPCHAT_ERROR = 'auth/LOGIN_WITH_SNAPCHAT_ERROR'
export const LOGIN_WITH_SNAPCHAT_SUCCESS = 'auth/LOGIN_WITH_SNAPCHAT_SUCCESS'

export const LOGOUT = 'auth/LOGOUT'
export const LOGOUT_ERROR = 'auth/LOGOUT_ERROR'
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'

export const REMOVE_ACCOUNT = 'auth/REMOVE_ACCOUNT'
export const REMOVE_ACCOUNT_ERROR = 'auth/REMOVE_ACCOUNT_ERROR'
export const REMOVE_ACCOUNT_SUCCESS = 'auth/REMOVE_ACCOUNT_SUCCESS'

export const SHOW_SNAPCHAT_LOGIN = 'auth/SHOW_SNAPCHAT_LOGIN'

export const UPDATE_AVATAR = 'auth/UPDATE_AVATAR'
export const UPDATE_AVATAR_ERROR = 'auth/UPDATE_AVATAR_ERROR'
export const UPDATE_AVATAR_SUCCESS = 'auth/UPDATE_AVATAR_SUCCESS'
