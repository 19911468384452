import * as events from '@socialquiz/core/events/reducer'
import { configureRootReducer } from '@socialquiz/core/store/configureRootReducer'
import { getPersistOptions } from '@socialquiz/core/store/persistence'

const modules = [events]

const appReducer = (state = {}, action) =>
  modules.reduce((acc, module) => {
    return {
      ...acc,
      [module.name]: module.reducer(state[module.name], action, state),
    }
  }, {})

export const persistOptions = getPersistOptions(modules)
export const rootReducer = configureRootReducer({ appReducer, modules })
