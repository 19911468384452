import { createOffline } from '@redux-offline/redux-offline'
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults'
import * as Sentry from '@sentry/browser'
import { effect } from '@socialquiz/core/api'
import { createLogger } from '@socialquiz/core/store/middleware/createLogger'
import { errorHandlerMiddleware } from '@socialquiz/core/store/middleware/errorHandlerMiddleware'
import { requestMiddleware } from '@socialquiz/core/store/middleware/requestMiddleware'
import { queue } from '@socialquiz/core/store/queue'
import { retry } from '@socialquiz/core/store/retry'
import { mergeDeepRight } from 'ramda'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { config } from '../config'
import { rootSaga } from '../sagas/rootSaga'
import { errorHandler } from './errorHandler'
import { stateInjection } from './middleware/stateInjection'
import { persistOptions, rootReducer } from './rootReducer'

const {
  middleware: offlineMiddleware,
  enhanceReducer,
  enhanceStore,
} = createOffline({
  ...defaultOfflineConfig,
  ...mergeDeepRight(config.store.offline, {
    persist: false,
    effect,
    queue,
    retry,
  }),
})

const persistedReducer = persistReducer(
  mergeDeepRight(persistOptions, config.store.offline.persistOptions),
  enhanceReducer(rootReducer)
)

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (e) => Sentry.captureException(e),
  })

  /**
   * The thunk middleware as to be first position so that other middlewares don't need to check if the action is a function.
   * The stateInjection comes right after as other middlewares rely on it.
   * The requestMiddleware has to be in front of the offlineMiddleware as it might decide to skip it.
   */
  const middleware = [
    thunk,
    stateInjection,
    requestMiddleware,
    offlineMiddleware,
    sagaMiddleware,
    ['development', 'testing'].includes(process.env.REACT_APP_ENV) &&
      createLogger(config.reduxLogger),
    errorHandlerMiddleware({ errorHandler }),
  ].filter(Boolean)

  const store = createStore(
    persistedReducer,
    compose(enhanceStore, applyMiddleware(...middleware))
  )

  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)

  if (process.env.REACT_APP_ENV === 'development') {
    window.__STORE__ = store
  }

  return [store, persistor]
}
