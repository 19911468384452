import { events } from '@socialquiz/core/events'
import * as actions from '@socialquiz/core/store/sagas/actions'
import * as deeplinking from './deeplinking'
import { eventFactory } from './eventFactories'
import { rootEventHandler } from './eventHandlers'

const modules = [deeplinking]

export const rootSaga = function* rootSaga() {
  yield events.subscribe(modules, eventFactory, rootEventHandler)
  yield actions.subscribe(modules)
}
