import React from 'react'

const TIMEOUT = 100

/**
 * Used to get the size and position of an element.
 * @returns {Array} [ref, clientRect]
 */
export const useClientRect = () => {
  const ref = React.useRef()
  const resizeTimeout = React.useRef(null)

  const [clientRect, setClientRect] = React.useState(
    document.createElement('div').getBoundingClientRect()
  )

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        setClientRect(ref.current?.getBoundingClientRect())
      }
    }, TIMEOUT)
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimeout.current)

      resizeTimeout.current = setTimeout(() => {
        if (ref.current) {
          setClientRect(ref.current?.getBoundingClientRect())
        }
      }, TIMEOUT)
    })

    return () => {
      clearTimeout(resizeTimeout.current)
    }
  }, [])

  return [ref, clientRect]
}
