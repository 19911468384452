import React from 'react'
import { Switch } from 'react-router'
import { Redirect, Route, Router as BrowserRouter } from 'react-router-dom'
import { View } from '../../components/View'
import { Screen as ScreenLanding } from '../../screens/ScreenLanding'
import { Screen as ScreenResult } from '../../screens/ScreenResult'
import { history } from '../history'

const styles = {
  container: { height: '100%', flex: 1 },
}

export class Router extends React.Component {
  static routes = ['/', '/:username']

  render() {
    return (
      <BrowserRouter history={history}>
        <View style={styles.container}>
          <Switch>
            <Route exact path="/" component={ScreenLanding} />
            <Route exact path="/:username" component={ScreenResult} />
            <Route component={() => <Redirect to="/" />} />
          </Switch>
        </View>
      </BrowserRouter>
    )
  }
}
