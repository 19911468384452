import { openInApp } from '@socialquiz/core/actions/resultsActions'
import { colors } from '@socialquiz/core/styles/colors'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box } from '../components/Box'
import { TouchableOpacity } from '../components/TouchableOpacity'

export const Name = 'ScreenLanding'

export const Header = null

export const Screen = () => {
  const dispatch = ReactRedux.useDispatch()

  const openDeeplink = React.useCallback(() => {
    dispatch(openInApp())
  }, [dispatch])

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(openInApp())
  //   }, 3000)
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      flex={1}
      alignItems="center"
      justifyContent="center"
      marginBottom={32}
      backgroundColor={colors.transparent}>
      <TouchableOpacity onPress={openDeeplink}>
        <img
          src={require('../assets/img/Name+logo.png')}
          height={63}
          width={256}
        />
      </TouchableOpacity>
    </Box>
  )
}
