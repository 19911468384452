import * as Sentry from '@sentry/browser'
import { events } from '@socialquiz/core/events'
import { getApiToken } from '@socialquiz/core/selectors/authSelectors'
import { history } from '../../navigation/history'

export const stateInjection = (store) => (next) => (action) => {
  const isEvent = action.type.startsWith('events/')
  const isReduxOfflineAction = !isEvent && action.type.includes('Offline/')

  Sentry.addBreadcrumb({
    category: isEvent ? 'event' : 'action',
    message: action.type,
    data: {
      route: { routeName: history.location.pathname },
    },
  })

  if (isReduxOfflineAction) {
    return next(action)
  }

  const state = store.getState()

  const enhancedAction = {
    ...action,
    meta: {
      ...action.meta,
      createdAt: Date.now(),
      route: { name: history.location.pathname, state: history.location.state },
      setup: {
        apiToken: action.meta?.offline ? getApiToken(state) : undefined,
      },
    },
  }

  const payload = next(enhancedAction)

  if (isEvent) {
    /**
     * All events are emitted and can be listened to with events.addListener,
     * e.g. for the FlatList's scroll to top feature.
     * We call next before emitting to make the most recent state available.
     */
    events.emit(payload.type, payload)
  }

  return payload
}
