import * as t from '../types/resultsTypes'

/**
 * Deeplink from webpage into app or store.
 * @param {{ username: string }} payload
 */
export const openInApp = (payload) => ({
  type: t.OPEN_IN_APP,
  payload,
})

export const refreshProfile = ({ username, ...payload }) => ({
  type: t.REFRESH_PROFILE,
  payload,
  meta: {
    offline: {
      effect: {
        path: `profile/name/${username}`,
        ...payload,
      },
      rollback: { type: t.REFRESH_PROFILE_ERROR },
      commit: {
        type: t.REFRESH_PROFILE_SUCCESS,
        meta: payload,
      },
    },
  },
})
