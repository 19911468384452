import * as Sentry from '@sentry/browser'
import { parseJson } from '@socialquiz/core/api'

export const errorHandler = async ({
  code = 'NO_CODE',
  payload = {},
  type,
}) => {
  switch (code) {
    default: {
      const response = await parseJson(payload)

      if (process.env.REACT_APP_ENV === 'development') {
        console.log(type, response) // eslint-disable-line no-console
      }

      Sentry.addBreadcrumb({
        category: 'Service',
        message: 'errorHandler',
        data: {
          payload: response,
          type,
        },
      })

      Sentry.captureMessage(code)
    }
  }
}
