import { PERSIST_REHYDRATE } from '@redux-offline/redux-offline/lib/constants'
import * as Sentry from '@sentry/browser'
import { events } from '@socialquiz/core/events'
import { getIsLoggedIn } from '@socialquiz/core/selectors/authSelectors'
import {
  LOGIN_WITH_SNAPCHAT_SUCCESS,
  LOGOUT_SUCCESS,
} from '@socialquiz/core/types/authTypes'
import { put, select, take } from 'redux-saga/effects'

const load = function* load() {
  try {
    /**
     * App is considered loaded when persisted data is loaded into state.
     */
    yield take(PERSIST_REHYDRATE)
    const isLoggedIn = yield select(getIsLoggedIn)
    yield put(events.load({ isLoggedIn }))
  } catch (e) {
    Sentry.captureException(e)
  }
}

const ready = function* ready() {
  try {
    /**
     * App is considered ready when app is mounted, rehydrated and
     * the navigation ref is available.
     */
    yield events.ensure([events.LOAD, events.MOUNT])
    yield put(events.ready())
  } catch (e) {
    Sentry.captureException(e)
  }
}

const auth = function* auth() {
  try {
    while (true) {
      yield take([events.READY, events.UNAUTH])

      const isLoggedIn = yield select(getIsLoggedIn)
      const isDelayed = !isLoggedIn

      if (!isLoggedIn) {
        yield take(LOGIN_WITH_SNAPCHAT_SUCCESS)
      }

      yield put(events.auth({ isDelayed }))
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

const unauth = function* unauth() {
  try {
    while (true) {
      yield take([LOGOUT_SUCCESS])
      yield put(events.unauth({}))
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

/**
 * This object maps event types to their respective event factories.
 * A factory dispatches one or multiple event actions based on certain conditions.
 */
export const eventFactory = {
  [events.LOAD]: [load],
  [events.READY]: [ready],
  [events.AUTH]: [auth],
  [events.UNAUTH]: [unauth],
}
