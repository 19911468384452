import { PERSIST_REHYDRATE } from '@redux-offline/redux-offline/lib/constants'
import { combineReducers } from 'redux'
import { LOGOUT_SUCCESS, REMOVE_ACCOUNT_SUCCESS } from '../types/authTypes'

const applyExperiments = (state) => {
  if (typeof state.experiments?.queued?.id !== 'undefined') {
    state.experiments.active = { ...state.experiments.queued }
    state.experiments.queued = {}
    state.experiments.isFromQueue = true
  } else if (state.experiments) {
    state.experiments.isFromQueue = false
  }

  return state
}

export const configureRootReducer = ({
  appReducer,
  modules,
  migrations,
  version,
}) => {
  const reducer =
    appReducer ??
    combineReducers(
      modules.reduce(
        (acc, module) => ({
          ...acc,
          [module.name]: module.reducer,
        }),
        {}
      )
    )

  const migrate = (state) => {
    if (migrations && version && state.__app__) {
      const currentVersion = state.__app__.storeVersion
      const newerVersions = Object.keys(migrations)
        .sort()
        .filter((v) => v > currentVersion && v <= version)

      if (newerVersions[0]) {
        return newerVersions.reduce(
          (acc, newVersion) => ({
            ...acc,
            ...migrations[newVersion]({
              ...acc,
              __app__: {
                ...acc.__app__,
                storeVersion: newVersion,
              },
            }),
          }),
          state
        )
      }
    }

    return state
  }

  const rootReducer = (state, action) => {
    let newState

    switch (action.type) {
      case PERSIST_REHYDRATE: {
        newState = applyExperiments(
          migrate(
            modules.reduce(
              (acc, module) => ({
                ...acc,
                [module.name]: {
                  ...state[module.name],
                  ...action.payload?.[module.name],
                },
              }),
              state
            )
          )
        )
        break
      }

      case LOGOUT_SUCCESS:
      case REMOVE_ACCOUNT_SUCCESS: {
        newState = modules.reduce(
          (acc, module) => ({
            ...acc,
            [module.name]:
              typeof module.persistence?.clear === 'function'
                ? module.persistence.clear(state[module.name])
                : module.initialState,
          }),
          state
        )
        break
      }

      default:
        newState = state
    }

    return reducer(newState, action)
  }

  return rootReducer
}
