const c = {
  blue: {
    [1]: '#3754E3',
    [2]: '#0F32DE',
    [3]: '#0D0D93',
    [4]: '#2C2C67',
    [5]: '#E7EBFF',
    [6]: '#5470FC',
    [7]: '#A5AEDE',
    [9]: '#2A2A8F',
    [10]: '#0F0F6D',
    [11]: '#4463F9',
  },
  green: {
    [1]: '#D3FFE2',
    [2]: '#15B64B',
    [3]: '#17DAB7',
    [4]: '#E8F5E9',
    [5]: '#A6F5C0',
    [6]: '#32B092',
    [7]: '#1A9779',
    [8]: '#C6FC9B',
  },
  mint: { [1]: '#C3F9EC', [2]: '#E7FDF8', [3]: '#BAEDE1', [4]: '#E1F5F1' },
  yellow: { [1]: '#FFFBC7', [2]: '#FFED49', [3]: '#E2C72C' },
  orange: { [1]: '#FFCB45', [2]: ' #F88A29' },
  red: { [1]: '#FFE7E3', [2]: '#FFA395', [3]: '#D23258', [4]: '#FFB2C5' },
  white: { [1]: '#FFFFFF' },
  gradient: { [5]: 'rgba(240, 241, 246, 0.16)' },
  grey: { [1]: '#BDBDBD', [2]: '#989EBD', [3]: '#F0F1F6', [4]: '#BFC4DB' },
  black: { [1]: '#313138', [2]: '#5E6275', [6]: '#000000' },
  stroke: {
    [1]: 'rgba(244, 244, 252, 0.2)',
    [2]: '#F4F4FC',
    [3]: '#E5EAFF',
    [4]: '#B6B6FF',
  },
  bg: {
    [1]: '#FAFBFF',
    [2]: '#FCFEFF',
    [3]: '#F8FEFF',
    [4]: '#F5F6FF',
    category: {
      [1]: '#FFEDDD',
      [2]: '#EFEEFF',
      [3]: '#FFF7CB',
      [4]: '#D6FBE0',
      [5]: '#FFEAF4',
      [6]: '#C8FABB',
      [7]: '#D2FFF4',
      [8]: '#DEFABB',
      [9]: '#F2E2FF',
      [10]: '#F9EED3',
      [11]: '#FFDEF4',
      [12]: '#FFE3E3',
      [13]: '#C4EDFF',
      [14]: 'rgba(255, 255, 255, 0.24)',
    },
    profile: {
      [1]: '#E9E7FF',
      [2]: '#FFFDC5',
      [3]: '#D9FFD3',
      [4]: '#FAE7FF',
    },
    screen: { [1]: '#FDFEFF', [2]: '#F8F9FF' },
  },
  category: {
    name: {
      [6]: '#B11257',
      [7]: '#79B72C',
      [8]: '#2AA1D3',
      [9]: '#2BD5AB',
      [10]: '#B261F4',
      [11]: '#59CB3B',
      [12]: '#DC42A8',
      [13]: '#BA9C52',
    },
  },
  pink: { [1]: '#ED6AA2' },
  violet: { [1]: '#6450DD', [2]: '#A6A6FF' },
}

export const colors = {
  appleRed: '#ff3b30',
  blueBackground: 'rgba(34, 57, 169, 1)',
  background: c.bg.screen[2],
  border: c.stroke[2],
  caption: '#989EBD',
  darkBlue: '#14171a',
  errorInfoRed: '#EA3E33',
  primary: c.blue[1],
  secondary: c.mint[1],
  greyDisabled: '#F9F9FA',
  primaryButtonShadow: 'rgba(64, 15, 34, 0.12)',
  mediumGrey: '#ceced2',
  neutralTextGreyLighter: '#88898F',
  noInternetGrey: '#88898F',
  text: c.black[1],
  turquoise: '#39B8AD',
  snapchat: '#fffc00',
  transparent: 'transparent',
  transparentWhite20: 'rgba(255,255,255,0.2)',
  yellowDark: '#FCF07E',
  ...c,
}
