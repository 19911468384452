window.quokka = window.quokka || {}
window.quokka.device = (() => {
  const includes = (string) => navigator.appVersion?.indexOf?.(string) !== -1

  const version = '0'

  switch (true) {
    case includes('iPhone') || includes('iPad') || includes('iPod'):
      return { os: 'ios', version }
    case includes('Android'):
      return { os: 'android', version }
    case includes('Windows'):
      return { os: 'windows', version }
    case includes('Mac'):
      return { os: 'mac', version }
    case includes('Linux'):
      return { os: 'linux', version }
    case includes('X11'):
      return { os: 'unix', version }
    default:
      return { os: 'unknown', version }
  }
})()
window.quokka.getWidth = () => document.documentElement.clientWidth
window.quokka.getHeight = () => document.documentElement.clientHeight - 50
window.quokka.getFullHeight = () => document.documentElement.clientHeight
window.quokka.isAndroid =
  window.navigator.userAgent.toLowerCase().indexOf('android') > -1
window.quokka.isDesktop = () => document.documentElement.clientWidth >= 768
window.quokka.logProduction = () => {}
window.quokka.renderApp = () => {}
window.quokka.isInstagramInAppBrowser = (() => {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  const isInstagram = ua.indexOf('Instagram') > -1 ? true : false

  return isInstagram
})()
window.quokka.isSnapchatInAppBrowser = (() => {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  const isSnapchat = ua.indexOf('Snapchat') > -1 ? true : false

  return isSnapchat
})()
window.quokka.whoami = (() => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return {
      browser: 'ie',
      version: tem[1],
    }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) {
      return {
        browser: tem.indexOf('Edge') > -1 ? 'edge' : 'opera',
        version: tem[2],
      }
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1])
  }

  // fixes netscape who reports version with user agent like "5.0 (iPhone)..."
  const version = M[1].match(/^([0-9]*\.?[0-9]*)\s*.*/)[1]

  return {
    browser: M[0].toLowerCase(),
    version: version,
  }
})()
