import { omit, mergeRight, pick } from 'ramda'
import React from 'react'
import { TouchableOpacity } from './TouchableOpacity'
import { View } from './View'
import * as PropsStyles from '@socialquiz/core/PropsStyles'

const styleProps = [
  PropsStyles.alignContent,
  PropsStyles.alignItems,
  PropsStyles.alignSelf,
  PropsStyles.aspectRatio,
  PropsStyles.backgroundColor,
  PropsStyles.borderBottomColor,
  PropsStyles.borderBottomEndRadius,
  PropsStyles.borderBottomLeftRadius,
  PropsStyles.borderBottomRightRadius,
  PropsStyles.borderBottomStartRadius,
  PropsStyles.borderBottomWidth,
  PropsStyles.borderColor,
  PropsStyles.borderEndColor,
  PropsStyles.borderEndWidth,
  PropsStyles.borderLeftColor,
  PropsStyles.borderLeftWidth,
  PropsStyles.borderRadius,
  PropsStyles.borderRightColor,
  PropsStyles.borderRightWidth,
  PropsStyles.borderStartColor,
  PropsStyles.borderStartWidth,
  PropsStyles.borderStyle,
  PropsStyles.borderTopColor,
  PropsStyles.borderTopEndRadius,
  PropsStyles.borderTopLeftRadius,
  PropsStyles.borderTopRightRadius,
  PropsStyles.borderTopStartRadius,
  PropsStyles.borderTopWidth,
  PropsStyles.borderWidth,
  PropsStyles.bottom,
  PropsStyles.color,
  PropsStyles.cursor,
  PropsStyles.display,
  PropsStyles.elevation,
  PropsStyles.flex,
  PropsStyles.flexBasis,
  PropsStyles.flexDirection ?? 'column',
  PropsStyles.flexGrow,
  PropsStyles.flexShrink,
  PropsStyles.flexWrap,
  PropsStyles.height,
  PropsStyles.justifyContent,
  PropsStyles.left,
  PropsStyles.margin,
  PropsStyles.marginBottom,
  PropsStyles.marginEnd,
  PropsStyles.marginHorizontal,
  PropsStyles.marginLeft,
  PropsStyles.marginRight,
  PropsStyles.marginStart,
  PropsStyles.marginTop,
  PropsStyles.marginVertical,
  PropsStyles.maxHeight,
  PropsStyles.maxWidth,
  PropsStyles.minHeight,
  PropsStyles.minWidth,
  PropsStyles.opacity,
  PropsStyles.overflow,
  PropsStyles.padding,
  PropsStyles.paddingBottom,
  PropsStyles.paddingEnd,
  PropsStyles.paddingHorizontal,
  PropsStyles.paddingLeft,
  PropsStyles.paddingRight,
  PropsStyles.paddingStart,
  PropsStyles.paddingTop,
  PropsStyles.paddingVertical,
  PropsStyles.position,
  PropsStyles.right,
  PropsStyles.top,
  PropsStyles.width,
  PropsStyles.zIndex,
  'borderBottomStyle',
  'boxShadow',
]

export const Box = React.forwardRef((props, ref) => {
  const computed = React.useMemo(() => {
    return {
      isTouchable:
        props.onPress ||
        props.onLongPress ||
        props.onPressIn ||
        props.onPressOut,
      props: omit(styleProps, props),
      style: mergeRight(pick(styleProps, props), {
        // backgroundColor: props.backgroundColor,
        ...props.style,
      }),
    }
  }, [props])

  if (computed.isTouchable) {
    return (
      <TouchableOpacity
        {...computed.props}
        forwardRef={ref}
        style={computed.style}
      />
    )
  }

  return <View {...computed.props} forwardRef={ref} style={computed.style} />
})

Box.Center = (props) => (
  <Box justifyContent="center" alignItems="center" {...props} />
)
