import { events } from '@socialquiz/core/events'
import { Style, StyleRoot as StyleProvider } from 'radium'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Provider as StateProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { globalStylesheet } from './globalStylesheet'
import { Router } from './navigation/components/Router'
import { configureStore } from './store/configureStore'

const [store, persistor] = configureStore()

store.dispatch(events.init())

const App = () => {
  useEffect(() => {
    store.dispatch(events.mount())
  }, [])

  return (
    <StateProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyleProvider>
          <Style rules={globalStylesheet} />
          <Router />
        </StyleProvider>
      </PersistGate>
    </StateProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

window.quokka.renderApp = () => {
  ReactDOM.render(null, document.getElementById('root'))
  ReactDOM.render(<App />, document.getElementById('root'))
}
