import { getConfig } from '../configure'

const sec = 1000

let config

export const retry = (action, retries) => {
  if (!config) {
    config = getConfig()
  }

  const retryCount =
    action.meta?.offline?.retryCount ?? config.api?.retryCount ?? 0

  return retries >= retryCount ? null : sec
}
