import { name } from '../constants/authConstants'

export const getState = (state) => state[name]

export const getApiToken = (state) => state[name].apiToken ?? ''

export const getIsBusy = (state) => state[name].isBusy

export const getIsLoggedIn = (state) => Boolean(getApiToken(state))

export const getLang = (state) => state[name].lang

export const getUserId = (state) => state[name].id

export const getUsername = (state) => state[name].username
